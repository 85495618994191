<template>
  <div>
    <QuizShell
      i18n_instructionsKey="quizzesInstruction"
      :currentQuestionNumber="currentQuestionIndex + 1"
      :totalNumberOfQuestions="quizQuestions.length"
      :correctCount="correctCount"
      :wrongCount="wrongCount"
      :displaySubmit="false"
      :displayNextQuestionBtn="displayNextButton"
      :displayPreviousQuestionBtn="displayPreviousButton"
      @onNextQuestion="goToNextQuestion"
      @onPreviousQuestion="goToPreviousQuestion"
      :soundEffects="!hideAnswerBox"
      :customHeader="customHeader"
      :backgroundMusic="quizData.backgroundMusic"
      :showAudioSetting="false"
    >
      <template v-slot:question class="questionBox">
        <div v-if="!quizFinished">
          <QuizPreview
            v-if="preQuizView && showPreQuizView"
            :preview="preQuizView"
            @onNext="hidePreQuizView()"
          ></QuizPreview>
          <QuizPreview
            v-if="showQuestionPreview"
            :preview="questionPreview"
            @onNext="showQuestionPreview = false"
          >
          </QuizPreview>
          <div v-else>
            <div v-if="currentQuestionImage">
              <v-img
                class="mb-2"
                :maxHeight="maxImageHeight"
                center
                cover
                :aspect-ratio="16 / 9"
                :src="currentQuestionImage"
              />
            </div>
            <div
              class="text-center font-weight-black text-h6 text-sm-h5 pa-1 questionBox__question"
            >
              <p v-html="currentQuestionText"></p>
            </div>
          </div>

          <div
            class="text-end"
            v-if="hasCurrentQuestionPreview && !showQuestionPreview"
          >
            <v-icon
              class="questionBox--videoIcon"
              @click="showQuestionPreview = true"
              >mdi-filmstrip</v-icon
            >
          </div>
        </div>
        <QuizPreview
          v-if="quizFinished && postQuizView && showPostQuizView"
          :preview="postQuizView"
          @onNext="hidePostQuizView()"
        ></QuizPreview>
        <div class="reportCard" v-if="quizFinished && !showPostQuizView">
          <ReportCard
            class="mb-5"
            :reportData="{
              level: quizTitle,
              totalNumberOfQuestions: quizQuestions.length,
              correctCount: correctCount
            }"
          />
          <v-btn color="primary" @click="playAgain()">{{
            $t('Play Again')
          }}</v-btn>
        </div>
        <AreYouSure
          :visible="areYouSureDialog"
          :cardTitle="$t('are_you_sure_quiz_title')"
          :cardText="$t('are_you_sure_quiz_content')"
          @areYouSure="areYouSure"
          @close="areYouSureDialog = false"
        ></AreYouSure>
      </template>

      <template #answer>
        <div v-if="!hideAnswerBox">
          <SingleSelectOptions
            v-if="currentQuestion"
            :options="currentQuestion?.options"
            :correctAnswerIndex="currentQuestionCorrectAnswerIndex"
            :showAnswer="showAnswer"
            @onShowAnswer="updateScore"
            @onOptionSelect="onOptionSelect"
          />

          <v-btn
            v-if="!showAnswer"
            color="primary"
            @click="showAnswer = !showAnswer"
            >{{ $t('Submit Answer') }}</v-btn
          >
          <v-btn
            v-if="showAnswer && !onLastQuestion"
            color="primary"
            @click="goToNextQuestion()"
            >{{ $t('Next Question') }}</v-btn
          >
          <v-btn
            v-if="showAnswer && onLastQuestion"
            color="primary"
            @click="viewScore()"
            >{{ $t('View Score') }}</v-btn
          >
        </div>
      </template>
    </QuizShell>
  </div>
</template>
<script>
import QuizShell from '../components/common/QuizShell.vue'
import QuizPreview from '../components/common/QuizPreview.vue'
import SingleSelectOptions from '../components/common/SingleSelectOptions.vue'
import ReportCard from '../components/common/QuizReportCard.vue'
import AreYouSure from '../components/AreYouSure.vue'
import Utils from '../util/Utils'
export default {
  name: 'Quiz',
  components: {
    QuizShell,
    QuizPreview,
    SingleSelectOptions,
    ReportCard,
    AreYouSure
  },
  mounted() {
    this.reset()
  },
  data() {
    return {
      showPreQuizView: false,
      showPostQuizView: false,
      currentQuestionIndex: -1,
      correctCount: 0,
      wrongCount: 0,
      displayNextButton: false,
      displayPreviousButton: false,
      showAnswer: false,
      showNextQuestionBtn: false,
      quizFinished: false,
      showQuestionPreview: false,
      selectedOptionIndex: -1,
      areYouSureDialog: false
    }
  },
  computed: {
    quizId() {
      return this.$route.params.quizTitle
    },
    quizData() {
      return this.$store.state.practicePlayPages.find(
        (p) => p.id === this.quizId
      )
    },
    quizTitle() {
      return this.quizData.name[this.$i18n.locale]
    },
    preQuizView() {
      if (this.quizData?.preQuizView?.type === 'image') {
        return {
          type: this.quizData.preQuizView.type,
          title: this.quizTitle,
          id: `quizzes/${this.quizId}/begin.png`,
          nextBtnText: 'Begin Quiz'
        }
      } else if (this.quizData?.preQuizView?.type === 'video') {
        return {
          type: this.quizData.preQuizView.type,
          title: `${this.$t('study-material')} : ${this.quizTitle}`,
          id: this.quizData?.preQuizView.id,
          nextBtnText: 'Begin Quiz'
        }
      } else if (this.quizData?.preQuizView?.type === 'audio') {
        return {
          type: this.quizData.preQuizView.type,
          title: `${this.$t('study-material')} : ${this.quizTitle}`,
          id: 'xxx',
          nextBtnText: 'Begin Quiz'
        }
      } else if (this.quizData?.preQuizView?.type === 'pdf') {
        return {
          type: this.quizData.preQuizView.type,
          title: `${this.$t('study-material')} : ${this.quizTitle}`,
          image: `quizzes/${this.quizId}/begin.png`,
          id: this.quizData.preQuizView.id,
          nextBtnText: 'Begin Quiz'
        }
      } else {
        return undefined
      }
    },
    customHeader() {
      if (this.preQuizView && this.showPreQuizView) {
        return `${this.preQuizView.title}`
      } else if (this.showQuestionPreview) {
        return this.currentQuestion.preview?.title
      } else if (
        (this.quizFinished && !this.showPostQuizView) ||
        this.showPostQuizView
      ) {
        return `${this.quizTitle}`
      } else {
        return undefined
      }
    },
    hideAnswerBox() {
      if (this.customHeader) {
        return true
      } else {
        return this.quizFinished
      }
    },
    postQuizView() {
      if (this.quizData.postQuizView) {
        return {
          type: this.quizData.postQuizView.type,
          title: this.quizTitle,
          id: `quizzes/${this.quizId}/end.png`,
          nextBtnText: 'Next'
        }
      } else {
        return undefined
      }
    },
    quizQuestions() {
      return this.$store.state.quizzes[this.quizId]
    },
    currentQuestion() {
      return (
        this.currentQuestionIndex != -1 &&
        this.quizQuestions[this.currentQuestionIndex]
      )
    },
    currentQuestionOptions() {
      return this.currentQuestion?.options
    },
    currentQuestionOptionsWithMultipleAnswers() {
      let mutlipleAnswersOptions = []
      if (this.currentQuestion?.question.indexOf('_A_') >= 0) {
        //transform from 'A = ભાવ ; B = માંદો' to {'_A_':ભાવ, '_B_':'માંદો'}
        this.currentQuestionOptions.forEach((o) => {
          let tempAnswers = {}
          let answerOptions = o.split(' ; ')
          answerOptions.forEach((a) => {
            const temp = a.split(' = ')
            tempAnswers[`_${temp[0]}_`] = temp[1]
          })
          mutlipleAnswersOptions.push(tempAnswers)
        })
      }
      //{'_A_':ભાવ, '_B_':'માંદો'}
      return mutlipleAnswersOptions
    },
    currentQuestionText() {
      if (this.currentQuestionIndex < 0) {
        return ''
      }
      let qText = this.quizQuestions[this.currentQuestionIndex].question
      //If question consists of multiple answers with _A_, _B_ etc..
      if (this.currentQuestionOptionsWithMultipleAnswers.length > 0) {
        //Is Any option selected??
        if (this.selectedOptionIndex > -1) {
          Object.keys(
            this.currentQuestionOptionsWithMultipleAnswers[
              this.selectedOptionIndex
            ]
          ).forEach((k) => {
            //replace each _A_ , _B_ etc in the question text with answers
            qText = this.replaceWithSpan(
              qText,
              k,
              this.currentQuestionOptionsWithMultipleAnswers[
                this.selectedOptionIndex
              ][k]
            )
          })
          return qText
        } else {
          //No option selected so replace _A_ with span
          Object.keys(
            this.currentQuestionOptionsWithMultipleAnswers[0]
          ).forEach((k) => {
            //replace each _A_ , _B_ etc in the question ext
            qText = this.replaceWithSpan(qText, k, k)
          })
          return qText
        }
      } else {
        return this.replaceWithSpan(
          qText,
          '_',
          this.currentQuestionOptions[this.selectedOptionIndex] || ''
        )
      }
    },
    currentQuestionImage() {
      if (this.currentQuestion?.questionImage) {
        return Utils.imgUrlByPath(
          `quizzes/${this.quizId}/${this.currentQuestion?.questionImage}`
        )
      } else return undefined
    },
    hasCurrentQuestionPreview() {
      return this.currentQuestion?.preview?.type === 'video'
    },
    currentQuestionCorrectAnswerIndex() {
      return this.currentQuestion?.options?.findIndex(
        (o) => o === this.currentQuestion.answer[0]
      )
    },
    onLastQuestion() {
      return this.currentQuestionIndex === this.quizQuestions.length - 1
    },
    maxImageHeight() {
      return Math.min(window.innerWidth, window.innerHeight * 0.5)
    },
    questionPreview() {
      return {
        ...this.currentQuestion.preview,
        nextBtnText: 'Next'
      }
    }
  },
  methods: {
    reset() {
      this.showPreQuizView = false
      if (this.preQuizView) {
        this.currentQuestionIndex = -1
        this.showPreQuizView = true
      } else {
        this.showPreQuizView = false
        this.currentQuestionIndex = 0
      }

      this.showPostQuizView = false
      this.correctCount = 0
      this.wrongCount = 0
      this.displayNextButton = false
      this.displayPreviousButton = false
      this.showAnswer = false
      this.showNextQuestionBtn = false
      this.quizFinished = false
      this.showQuestionPreview = this.hasCurrentQuestionPreview
      this.selectedOptionIndex = -1
    },
    hidePreQuizView() {
      this.areYouSureDialog = true
    },
    areYouSure(yesNo) {
      if (yesNo) {
        this.showPreQuizView = false
        this.currentQuestionIndex++
      }
      this.areYouSureDialog = false
    },
    hidePostQuizView() {
      this.showPostQuizView = false
      this.quizFinished = true
    },
    goToNextQuestion() {
      this.currentQuestionIndex++
      this.showAnswer = false
      this.showNextQuestionBtn = false
      this.showQuestionPreview = this.hasCurrentQuestionPreview
      this.selectedOptionIndex = -1
    },
    goToPreviousQuestion() {
      this.currentQuestionIndex--
    },
    onOptionSelect(selectedOptionIndex) {
      this.selectedOptionIndex = selectedOptionIndex
    },
    updateScore(isAnswerCorrect) {
      isAnswerCorrect ? this.correctCount++ : this.wrongCount++
      if (this.currentQuestionIndex < this.quizQuestions.length - 1) {
        this.showNextQuestionBtn = true
      }
      if (this.selectedOptionIndex === -1)
        this.selectedOptionIndex = this.currentQuestionCorrectAnswerIndex
    },
    viewScore() {
      this.quizFinished = true
      if (this.postQuizView) {
        this.showPostQuizView = true
      }
    },
    playAgain() {
      this.reset()
    },
    replaceWithSpan(text, searchFor, replaceWith) {
      return text.replaceAll(searchFor, `<span>${replaceWith}</span>`)
    }
  }
}
</script>
<style lang="scss" scoped>
.questionBox {
  &__question {
    word-break: break-word;

    ::v-deep span {
      border: 1px solid var(--v-primary-base);
      min-width: 3rem;
      display: inline-block;
      color: white;
      background-color: var(--v-primary-base);
      padding: 1px 5px 0 5px;
    }
  }

  &--videoIcon {
    color: var(--v-primary-lighten3);
  }
}
.reportCard {
  text-align: center;
}
</style>
